import _ from 'lodash';
import Vue from 'vue';

const breakpoints = Vue.observable({
  screen: {},
});

function onResize() {
  const style = getComputedStyle(document.body);
  const sm = style.getPropertyValue('--bs-breakpoint-sm').replace('px', '');
  const md = style.getPropertyValue('--bs-breakpoint-md').replace('px', '');
  const lg = style.getPropertyValue('--bs-breakpoint-lg').replace('px', '');
  const xl = style.getPropertyValue('--bs-breakpoint-xl').replace('px', '');
  const xxl = 1600;
  const width = window.innerWidth;

  breakpoints.screen = {
    xs: width < sm,
    sm: width >= sm && width < md,
    md: width >= md && width < lg,
    lg: width >= lg && width < xl,
    xl: width >= xl && width < xxl,
    xxl: width >= xxl,
    smUp: width >= sm,
    mdUp: width >= md,
    lgUp: width >= lg,
    xlUp: width >= xl,
    smDown: width < md,
    mdDown: width < lg,
    lgDown: width < xl,
    xlDown: width < xxl,
  };
}

window.onresize = _.debounce(onResize, 100);

export { breakpoints, onResize };
